import React from 'react';
import SEO from '../../utils/SEO';
import { useNavigate } from 'react-router-dom';
import CookieConsent from '../../components/CookieConsent';
import {
	HomeContainer, IntroSection, InfoHeader, InfoSection, PlatformSection, PlatformItem,
	PlatformImage, PlatformLink, PlatformDetails, BulletList, BulletItem, ButtonWrapper,
	FAQSection, FAQItem, FAQHeader, BlogSection, BlogItem, ArrowWrapper
} from './HomeElements';
import { FaExternalLinkAlt, FaQuestionCircle, FaRegNewspaper, FaArrowRight } from 'react-icons/fa';
import primexbtLogo from '../../images/primexbt/primexbt-logo.svg';
import mexclogo from '../../images/mexc/mexc-logo.svg'; 
import phemexlogo from '../../images/phemex/phemex-logo.svg'; 
import margexlogo from '../../images/margex/margex-logo.svg'; 
import gateiologo from '../../images/gateio/gateio-logo.svg';

const platforms = [
	{
		name: 'PrimeXBT',
		link: 'https://go.prmx.co/visit/?bta=39870&brand=primexbt',
		description: '',
		offer: 'Låg avgift och upp till 200x hävstång på Bitcoin!',
		image: primexbtLogo,
		bullets: ['Kryptovalutor och aktier', 'Upp till 200x hävstång', 'Höga Bonuserbjudanden']
	},
	{
		name: 'Margex',
		link: 'https://margex.com/?rid=17668826',
		description: '',
		offer: 'Upp till 100x hävstång och snabba uttag!',
		image: margexlogo,
		bullets: [
			'Isolerad marginalhandel',
			'Copy Trading tillgängligt',
			'Användarvänlig plattform'
		]
	},
	{
		name: 'Gate.io',
		link: 'https://www.gate.io/referral/invite/VLBCVF8KVG_0_103',
		description: '',
		offer: 'Brett utbud av altcoins och upp till 10x hävstång!',
		image: gateiologo,
		bullets: [
			'Över 1 700 kryptovalutor',
			'Avancerade handelsverktyg',
			'Låga avgifter'
		]
	},
	{
		name: 'MEXC',
		link: 'https://promote.mexc.com/a/WbsvcOf0',
		description: '',
		offer: 'Upp till 125x hävstång på många kryptovalutor!',
		image: mexclogo,
		bullets: [
			'Över 1 800 kryptovalutor',
			'Snabba och säkra uttag',
			'Låga avgifter utan maker-avgift'
		]
	},
	{
		name: 'Phemex',
		link: 'https://phemex.com/register?group=5974&referralCode=HL4JS7',
		description: '',
		offer: 'Upp till 100x hävstång och avgiftsfri spothandel!',
		image: phemexlogo,
		bullets: [
			'Avgiftsfri handel för premium',
			'Upp till 100x hävstång på derivat',
			'Avancerade verktyg för handel'
		]
	}
];

const Home = () => {
	const navigate = useNavigate();

	const handleGuideNavigation = () => {
		navigate('/kom-igang');
	};

	const handleNewsNavigation = () => {
		navigate('/nyheter');
	};

	return (
		<HomeContainer>
			{/* Helmet for SEO Metadata */}
<SEO
  title="Kryptotrading.se – Din guide till Bitcoin & Kryptohandel"
  description="Utforska de bästa plattformarna för kryptotrading och margin trading med hög hävstång. Perfekt för svenska handlare som vill maximera sina möjligheter på kryptomarknaden. Lär dig hitta rätt plattform och börja handla idag!"
  canonical="https://kryptotrading.se/"
>
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "Kryptotrading.se",
      "url": "https://www.kryptotrading.se",
      "logo": "https://www.kryptotrading.se/images/logo.png",
      "description":
        "En svensk guide för kryptovalutahandel med hög hävstång och de bästa plattformarna för svenska handlare.",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Stockholm",
        "addressCountry": "SE",
      },
      "telephone": "+46 70 123 45 67",
    })}
  </script>
</SEO>

			{/* Rest of your existing code */}
			<IntroSection>
				<h1>Hitta de mest pålitliga kryptotrading-plattformarna</h1>
				<p>Upptäck de bästa plattformarna för kryptohandel med våra recensioner och insikter anpassade för både nya och erfarna handlare.</p>
			</IntroSection>

			<InfoHeader>Din expertguide för kryptotrading i Sverige</InfoHeader>
			<InfoSection>
				<p>Vi granskar och rekommenderar de säkraste och mest effektiva kryptotrading-plattformarna för svenska handlare.</p>
				<p>Oavsett din erfarenhetsnivå hjälper vi dig att navigera kryptomarknaden och hitta de bästa handelsmöjligheterna.</p>
			</InfoSection>

			<PlatformSection>
				{platforms.map((platform, index) => (
					<PlatformItem key={index}>
						<a href={platform.link} target="_blank" rel="noopener noreferrer">
							<PlatformImage src={platform.image} alt={`${platform.name} banner`} />
						</a>
						<PlatformDetails>
							<p>{platform.offer}</p>
						</PlatformDetails>
						<BulletList>
							{platform.bullets.map((bullet, idx) => (
								<BulletItem key={idx}>{bullet}</BulletItem>
							))}
						</BulletList>
						<ButtonWrapper>
							<PlatformLink href={platform.link} target="_blank" rel="noopener noreferrer">
								Besök {platform.name} <FaExternalLinkAlt />
							</PlatformLink>
						</ButtonWrapper>
					</PlatformItem>
				))}
			</PlatformSection>

			<FAQSection>
				<h2><FaQuestionCircle /> Kom igång</h2>
				<FAQItem>
					<FAQHeader>Vad är en kryptotrading-plattform?</FAQHeader>
					<p>En kryptotrading-plattform är en tjänst som tillåter användare att köpa och sälja kryptovalutor.</p>
				</FAQItem>
				<FAQItem>
					<FAQHeader>Hur gör jag insättningar med kryptovaluta?</FAQHeader>
					<p>För att göra en insättning, välj en kryptovaluta från listan över accepterade betalningsmetoder, ange din insättningsadress och beloppet du vill sätta in.</p>
				</FAQItem>
				<ArrowWrapper onClick={handleGuideNavigation}>
					<FaArrowRight />
				</ArrowWrapper>
			</FAQSection>

			<BlogSection>
				<h2><FaRegNewspaper /> Nyheter</h2>
				<BlogItem>
					<a href="/nyheter/bitcoin-nya-all-time-highs-och-overtraffar-silver">
						Bitcoin når nya all-time highs och överträffar silver
					</a>
				</BlogItem>
				<BlogItem>
					<a href="/nyheter/vad-trumps-seger-kan-innebara-for-bitcoin-och-kryptomarknaden">
						Vad Trumps seger kan innebära för Bitcoin och kryptomarknaden
					</a>
				</BlogItem>
				<BlogItem>
					<a href="/nyheter/bitcoin-gar-in-i-bull-period">
						Bitcoin går in i en bull-period
					</a>
				</BlogItem>
				<BlogItem>
					<a href="/nyheter/fed-sanker-rantan-krypto-stiger">
						Fed sänker räntan – krypto stiger
					</a>
				</BlogItem>
				<ArrowWrapper onClick={handleNewsNavigation}>
					<FaArrowRight />
				</ArrowWrapper>
			</BlogSection>

			<CookieConsent />
		</HomeContainer>
	);
};

export default Home;

