import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async'; // Import react-helmet
import { FaUser, FaEnvelope, FaCommentDots, FaTag } from 'react-icons/fa';
import {
    ContactContainer,
    Title,
    Description,
    ContactForm,
    Input,
    InputField,
    TextArea,
    Icon,
    SubmitButton
} from './ContactElements';
import Notification from './Notification';

const Contact = () => {
    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const validateForm = async (event) => {
        event.preventDefault();
        let newErrors = {};

        const name = event.target[0].value;
        const email = event.target[1].value;
        const subject = event.target[2].value;
        const message = event.target[3].value;

        if (!name) newErrors.name = 'Namn är obligatoriskt';
        if (!email.includes('@')) newErrors.email = 'Ogiltig e-postadress';
        if (!subject) newErrors.subject = 'Ämnet är obligatoriskt';
        if (!message) newErrors.message = 'Meddelande är obligatoriskt';

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const baseURL =
                    process.env.NODE_ENV === 'development'
                        ? 'http://localhost:5000/api/contact'
                        : 'https://kryptotrading.se/api/contact';

                const response = await fetch(baseURL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ name, email, subject, message }),
                });

                if (response.ok) {
                    setNotification({ message: 'Meddelandet har skickats!', type: 'success' });
                    setIsSubmitted(true);
                    event.target.reset();
                    setTimeout(() => setIsSubmitted(false), 5000);
                } else {
                    setNotification({ message: 'Ett fel inträffade. Försök igen senare.', type: 'error' });
                }
            } catch (error) {
                console.error('Error:', error);
                setNotification({ message: 'Kunde inte skicka meddelandet. Kontrollera din anslutning.', type: 'error' });
            }
        } else {
            setNotification({ message: 'Formuläret är inte komplett, kontrollera fälten.', type: 'error' });
        }
    };

    const closeNotification = () => {
        setNotification(null);
    };

    return (
        <ContactContainer>
            {/* Helmet for SEO Metadata */}
<Helmet>
  {/* Optimized Meta Title */}
  <title>Kontakta Oss – Frågor om Kryptotrading & Bitcoin</title>

  {/* Optimized Meta Description */}
  <meta
    name="description"
    content="Kontakta Kryptotrading.se för hjälp och frågor om kryptovalutahandel, plattformar, Bitcoin och hög hävstång. Vi svarar snabbt och hjälper svenska handlare att navigera kryptovärlden."
  />

  {/* Keywords */}
  <meta
    name="keywords"
    content="kontakta oss, kryptotrading, Bitcoin, kryptovalutor, support, kryptohandel"
  />

  {/* Open Graph Metadata */}
  <meta property="og:title" content="Kontakta Oss – Frågor om Kryptotrading & Bitcoin" />
  <meta
    property="og:description"
    content="Har du frågor om Bitcoin, kryptotrading eller andra kryptovalutor? Kontakta oss på Kryptotrading.se och få hjälp direkt."
  />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.kryptotrading.se/kontakt" />
  <meta
    property="og:image"
    content="https://www.kryptotrading.se/images/contact-og-image.jpg"
  />

  {/* Canonical URL */}
  <link rel="canonical" href="https://www.kryptotrading.se/kontakt" />

  {/* Structured Data (Schema.org) */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "ContactPage",
      "name": "Kontakta Oss – Frågor om Kryptotrading & Bitcoin",
      "description":
        "Kontakta Kryptotrading.se för hjälp och frågor om kryptovalutahandel, plattformar, Bitcoin och hög hävstång. Vi hjälper svenska handlare att navigera kryptovärlden.",
      "url": "https://www.kryptotrading.se/kontakt",
      "mainEntity": {
        "@type": "Organization",
        "name": "Kryptotrading.se",
        "url": "https://www.kryptotrading.se",
        "contactPoint": {
          "@type": "ContactPoint",
          "contactType": "Support",
          "email": "support@kryptotrading.se",
          "availableLanguage": ["Svenska", "Engelska"],
        },
      },
    })}
  </script>
</Helmet>


            <Title>Kontakta Oss - Kryptotrading.se</Title>
            <Description>
                Har du frågor eller förfrågningar? Fyll i formuläret nedan så återkommer vi till dig så snart
                som möjligt.
            </Description>

            <ContactForm noValidate onSubmit={validateForm}>
                <Input>
                    <Icon>
                        <FaUser aria-hidden="true" />
                    </Icon>
                    <InputField
                        type="text"
                        placeholder="Ditt namn"
                        aria-label="Ditt namn"
                        disabled={isSubmitted}
                    />
                </Input>
                <Input>
                    <Icon>
                        <FaEnvelope aria-hidden="true" />
                    </Icon>
                    <InputField
                        type="email"
                        placeholder="Din e-postadress"
                        aria-label="Din e-postadress"
                        disabled={isSubmitted}
                    />
                </Input>
                <Input>
                    <Icon>
                        <FaTag aria-hidden="true" />
                    </Icon>
                    <InputField
                        type="text"
                        placeholder="Ämne"
                        aria-label="Ämne"
                        disabled={isSubmitted}
                    />
                </Input>
                <Input>
                    <Icon>
                        <FaCommentDots aria-hidden="true" />
                    </Icon>
                    <TextArea
                        placeholder="Ditt meddelande"
                        rows="5"
                        aria-label="Ditt meddelande"
                        disabled={isSubmitted}
                    />
                </Input>
                <SubmitButton
                    type="submit"
                    aria-label="Skicka"
                    disabled={isSubmitted}
                >
                    Skicka
                </SubmitButton>
            </ContactForm>

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={closeNotification}
                />
            )}
        </ContactContainer>
    );
};

export default Contact;

