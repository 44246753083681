import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const slideInUp = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const slideOutDown = keyframes`
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(20px); }
`;

const CookieBanner = styled.div`
  position: fixed;
  bottom: 20px;
  left: 40%; /* Position för desktop */
  transform: translateX(-50%);
  background: linear-gradient(90deg, #1e3a8a, #3b82f6);
  color: #ffffff;
  padding: 1.5rem 2rem;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  animation: ${({ show }) => (show ? slideInUp : slideOutDown)} 0.5s ease forwards;
  width: ${({ expanded }) => (expanded ? '98%' : '95%')};
  max-width: ${({ expanded }) => (expanded ? '600px' : '550px')};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    left: 2rem; /* Flyttar bannern till vänster för mobil */
    transform: none;
    width: 90%;
    max-width: 320px;
    padding: 1rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  justify-content: space-evenly;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const CookieButton = styled.button`
  background-color: #ffffff;
  color: #1e3a8a;
  border: 1px solid #1e3a8a;
  border-radius: 24px;
  padding: 0.4rem 1.2rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #e0f0ff;
    color: #1e3a8a;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
    width: 100%;
  }
`;

const SettingsPanel = styled.div`
  background: linear-gradient(90deg, #1e3a8a, #3b82f6);
  color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  display: ${({ show }) => (show ? 'block' : 'none')};
  animation: ${({ show }) => (show ? slideInUp : slideOutDown)} 0.3s ease forwards;
  text-align: left;
  width: 100%;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  input {
    margin-right: 0.5rem;
  }

  label {
    color: #ffffff;
    white-space: nowrap;
  }
`;

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const acceptedCookies = localStorage.getItem('acceptedCookies');
    if (!acceptedCookies) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    setShowBanner(false);
    setTimeout(() => {
      localStorage.setItem('acceptedCookies', JSON.stringify(cookiePreferences));
    }, 500);
  };

  const handleSettings = () => {
    setShowSettings((prev) => !prev);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCookiePreferences((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  if (!showBanner) {
    return null;
  }

  return (
    <>
      {/* SEO Metadata */}
      <meta name="description" content="Kryptotrading.se använder cookies för att förbättra användarupplevelsen, anpassa innehåll och erbjuda de bästa tjänsterna för kryptovalutahandel." />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "Kryptotrading.se",
          "description": "En webbplats som använder cookies för att förbättra användarupplevelsen.",
          "url": "https://www.kryptotrading.se",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://www.kryptotrading.se?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        })}
      </script>
      <CookieBanner expanded={showSettings} show={showBanner} role="dialog" aria-label="Cookie consent banner">
        <span>Vi använder cookies för att förbättra din upplevelse. Läs vår cookiepolicy för mer information.</span>
        <ButtonGroup>
          <CookieButton onClick={handleSettings}>Inställningar</CookieButton>
          <CookieButton onClick={handleAccept}>Acceptera</CookieButton>
        </ButtonGroup>
        <SettingsPanel show={showSettings}>
          <CheckboxContainer>
            <input
              type="checkbox"
              name="necessary"
              checked={cookiePreferences.necessary}
              onChange={handleCheckboxChange}
              disabled
              aria-label="Nödvändiga cookies (obligatoriska)"
            />
            <label>Nödvändiga cookies (obligatoriska)</label>
          </CheckboxContainer>
          <CheckboxContainer>
            <input
              type="checkbox"
              name="analytics"
              checked={cookiePreferences.analytics}
              onChange={handleCheckboxChange}
              aria-label="Analytiska cookies (för att förbättra användarupplevelsen)"
            />
            <label>Analytiska cookies (för att förbättra användarupplevelsen)</label>
          </CheckboxContainer>
          <CheckboxContainer>
            <input
              type="checkbox"
              name="marketing"
              checked={cookiePreferences.marketing}
              onChange={handleCheckboxChange}
              aria-label="Marknadsföringscookies (för anpassad marknadsföring)"
            />
            <label>Marknadsföringscookies (för anpassad marknadsföring)</label>
          </CheckboxContainer>
        </SettingsPanel>
      </CookieBanner>
    </>
  );
};

export default CookieConsent;

