// Platforms.js

import React from 'react';
import { Helmet } from 'react-helmet-async'; // Import react-helmet
import {
  PlatformsContainer,
  PlatformBlock,
  PlatformLogo,
  PlatformTitle,
  PlatformDescription,
  ProsConsContainer,
  ProsConsList,
  ProsConsItem,
  ConsItem,
  ReviewDate,
  ReviewButton,
  ProsConsTitle,
} from './PlatformElements';
import { FiExternalLink } from 'react-icons/fi';
import primexbtLogo from '../../images/primexbt/primexbt-logo.svg';
import mexclogo from '../../images/mexc/mexc-logo.svg';
import phemexlogo from '../../images/phemex/phemex-logo.svg';
import margexlogo from '../../images/margex/margex-logo.svg';
import gateiologo from '../../images/gateio/gateio-logo.svg';

const platformsData = [
  {
    name: 'PrimeXBT',
    description: 'PrimeXBT erbjuder låga avgifter och upp till 200x hävstång på Bitcoin. Deras plattform är populär bland erfarna traders för dess avancerade handelsverktyg och pålitliga kundsupport.',
    logo: primexbtLogo,
    date: '2024-10-10',
    pros: ['Upp till 200x hävstång', 'Kryptovalutor och aktier', 'Höga Bonuserbjudanden'],
    cons: ['Hög risk på grund av hävstång', 'Begränsade fiatvalutor', 'Uttagstider upp till 24 timmar'],
    link: 'https://go.prmx.co/visit/?bta=39870&brand=primexbt'
  },
  {
    name: 'Margex',
    description: 'Margex är känt för sin isolerade marginalhandel och snabb uttagsprocess. Med en användarvänlig plattform och tillgång till Copy Trading är det ett bra val för både nybörjare och mer avancerade traders.',
    logo: margexlogo,
    date: '2024-10-12',
    pros: ['Isolerad marginalhandel', 'Copy Trading tillgängligt', 'Användarvänlig plattform'],
    cons: ['Begränsat utbud av kryptovalutor', 'Inga fiatinsättningar tillgängliga'],
    link: 'https://margex.com/?rid=17668826'
  },
  {
    name: 'Gate.io',
    description: 'Gate.io är en global kryptovalutabörs som erbjuder upp till 10x hävstång och ett brett utbud av altcoins, inklusive många Solana-baserade tokens. Plattformen är populär för sina avancerade handelsverktyg och låga avgifter, vilket gör den attraktiv för både nybörjare och erfarna traders.',
    logo: gateiologo,
    date: '2024-10-13',
    pros: ['Upp till 10x hävstång', 'Brett utbud av altcoins', 'Låga handelsavgifter'],
    cons: ['Begränsat stöd för fiatvalutor', 'Komplex gränssnitt för nybörjare'],
    link: 'https://www.gate.io/referral/invite/VLBCVF8KVG_0_103'
  },
  {
    name: 'Phemex',
    description: 'Phemex erbjuder avgiftsfri spothandel och upp till 100x hävstång. Plattformen är särskilt populär för sina avancerade handelsverktyg och användarvänlighet.',
    logo: phemexlogo,
    date: '2024-10-12',
    pros: ['Avancerade handelsverktyg', 'Cashback och VIP-erbjudanden', 'Handel med derivatmarknader'],
    cons: ['Komplex gränssnitt för nybörjare', 'Begränsat antal kryptovalutor'],
    link: 'https://phemex.com/register?group=5974&referralCode=HL4JS7'
  },
  {
    name: 'MEXC',
    description: 'MEXC är känd för sitt stora utbud av kryptovalutor och snabba uttag. Plattformen erbjuder upp till 125x hävstång och konkurrenskraftiga avgifter, vilket gör det till ett bra val för många traders.',
    logo: mexclogo,
    date: '2024-10-11',
    pros: ['Stort utbud av kryptovalutor', 'Omedelbara kryptouttag', 'Låga avgifter'],
    cons: ['Begränsat stöd för fiatinsättningar'],
    link: 'https://promote.mexc.com/a/WbsvcOf0'
  }
];

const Platforms = () => {
  return (
    <>
<Helmet>
  {/* Optimized Meta Title */}
  <title>Bästa Kryptotrading Börserna för Svenskar med Högst Hävstång</title>

  {/* Optimized Meta Description */}
  <meta
    name="description"
    content="Utforska de bästa kryptotrading-börserna för svenskar. Plattformar med hög hävstång, låga avgifter och breda utbud av kryptovalutor. Läs om PrimeXBT, Gate.io, MEXC, Phemex och Margex."
  />

  {/* Keywords */}
  <meta
    name="keywords"
    content="kryptotrading, hög hävstång, PrimeXBT, Gate.io, MEXC, Phemex, Margex, låga avgifter, svenska traders"
  />

  {/* Open Graph Metadata */}
  <meta
    property="og:title"
    content="Bästa Kryptotrading Börserna för Svenskar med Högst Hävstång"
  />
  <meta
    property="og:description"
    content="Utforska de bästa kryptotrading-börserna för hög hävstång och låga avgifter. Läs om PrimeXBT, Gate.io, MEXC, Phemex och Margex, perfekt för svenska traders."
  />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.kryptotrading.se/plattformar" />
  <meta
    property="og:image"
    content="https://www.kryptotrading.se/images/platforms-og-image.jpg"
  />

  {/* Canonical URL */}
  <link rel="canonical" href="https://www.kryptotrading.se/plattformar" />

  {/* Structured Data (Schema.org) */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "ItemList",
      name: "Bästa Kryptotrading Börserna",
      description:
        "Lista över de bästa kryptotrading-börserna med hög hävstång och låga avgifter.",
      itemListElement: platformsData.map((platform, index) => ({
        "@type": "ListItem",
        position: index + 1,
        name: platform.name,
        url: platform.link,
        description: platform.description,
        review: {
          "@type": "Review",
          reviewBody: platform.description,
          author: {
            "@type": "Person",
            name: "Kryptotrading.se",
          },
          datePublished: platform.date,
        },
      })),
    })}
  </script>
</Helmet>



      <PlatformsContainer>
        {platformsData.map((platform, index) => (
          <PlatformBlock key={index}>
            <PlatformLogo src={platform.logo} alt={`Logo för ${platform.name}`} />
            <PlatformTitle>{platform.name}</PlatformTitle>
            <PlatformDescription>{platform.description}</PlatformDescription>
            <ProsConsContainer>
              <div>
                <ProsConsTitle>Fördelar</ProsConsTitle>
                <ProsConsList>
                  {platform.pros.map((pro, idx) => (
                    <ProsConsItem key={idx}>{pro}</ProsConsItem>
                  ))}
                </ProsConsList>
              </div>
              <div>
                <ProsConsTitle>Nackdelar</ProsConsTitle>
                <ProsConsList>
                  {platform.cons.map((con, idx) => (
                    <ConsItem key={idx}>{con}</ConsItem>
                  ))}
                </ProsConsList>
              </div>
            </ProsConsContainer>
            <ReviewDate>Reviewed on: {platform.date}</ReviewDate>
            <ReviewButton href={platform.link} target="_blank" rel="noopener noreferrer">
              Besök {platform.name} <FiExternalLink className="icon" />
            </ReviewButton>
          </PlatformBlock>
        ))}
      </PlatformsContainer>
    </>
  );
};

export default Platforms;

