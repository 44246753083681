import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import {
  NewsContainer,
  Header,
  SubHeader,
  ArticleList,
  ArticleItem,
  ArticleTitle,
  ArticleDate,
  ArticleSnippet,
  NewsletterContainer,
  Title,
  Description,
  EmailForm,
  Input,
  SubmitButton,
  BenefitsSection,
  BenefitItem,
  ConfirmationText,
  ArrowWrapper,
  LoadMoreButton,
} from './NewsElements';
import { FaArrowRight } from 'react-icons/fa';
import Notification from '../Contact/Notification';

const News = () => {
  const [articles, setArticles] = useState([]);
  const [email, setEmail] = useState('');
  const [notification, setNotification] = useState(null); // For notifications
  const navigate = useNavigate();

  // Fetch articles from the backend
  useEffect(() => {
    const baseURL = 'https://kryptotrading.se/api/nyheter/articles';

    fetch(baseURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch articles.');
        }
        return response.json();
      })
      .then((data) => {
        const sortedArticles = data.sort((a, b) => new Date(b.published_date) - new Date(a.published_date));
        setArticles(sortedArticles);
      })
      .catch((error) => console.error('Error fetching articles:', error));
  }, []);

  // Navigate to the selected article page
  const handleArticleNavigation = (slug) => {
    navigate(`/nyheter/${slug}`);
  };

  // Handle newsletter sign-up
  const handleNewsletterSignup = async (event) => {
    event.preventDefault();

    if (!email.includes('@')) {
      setNotification({ message: 'Ogiltig e-postadress.', type: 'error' });
      return;
    }

    try {
      const response = await fetch('https://kryptotrading.se/api/newsletter', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setNotification({ message: 'Tack för att du skrev upp dig på vårt nyhetsbrev!', type: 'success' });
        setEmail('');
      } else {
        setNotification({ message: 'Ett fel inträffade. Försök igen senare.', type: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
      setNotification({ message: 'Kunde inte skicka begäran. Kontrollera din anslutning.', type: 'error' });
    }
  };

  const closeNotification = () => {
    setNotification(null);
  };

  return (
    <NewsContainer>
<Helmet>
  {/* Optimized Meta Title */}
  <title>Nyheter om Bitcoin & Krypto – För Svenska Handlare</title>

  {/* Optimized Meta Description */}
  <meta
    name="description"
    content="Håll dig uppdaterad med de senaste nyheterna och trenderna inom kryptotrading. Perfekt för svenska handlare som vill ligga steget före i kryptovärlden med Bitcoin, Ethereum, och andra altcoins."
  />

  {/* Keywords */}
  <meta
    name="keywords"
    content="kryptonyheter, kryptovalutor, kryptotrading, bitcoin nyheter, ethereum trender, svenska handlare, altcoins"
  />

  {/* Open Graph Metadata */}
  <meta property="og:title" content="Nyheter om Bitcoin & Krypto – För Svenska Handlare" />
  <meta
    property="og:description"
    content="Läs de senaste kryptonyheterna och trenderna inom kryptotrading. Perfekt för svenska handlare som vill vara uppdaterade om Bitcoin, Ethereum och altcoins."
  />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.kryptotrading.se/nyheter" />
  <meta property="og:image" content="https://www.kryptotrading.se/images/news-og-image.jpg" />

  {/* Canonical URL */}
  <link rel="canonical" href="https://www.kryptotrading.se/nyheter" />
</Helmet>
      <Header>Nyheter</Header>
      <SubHeader>De senaste nyheterna och trenderna inom kryptovärlden.</SubHeader>

      <ArticleList>
        {articles.length > 0 ? (
          articles.map((article) => (
            <ArticleItem key={article.id} onClick={() => handleArticleNavigation(article.slug)}>
              <ArticleTitle>{article.title}</ArticleTitle>
              <ArticleDate>{new Date(article.published_date).toLocaleDateString()}</ArticleDate>
              <ArticleSnippet>{article.preview}</ArticleSnippet>
              <ArrowWrapper>
                <FaArrowRight />
              </ArrowWrapper>
            </ArticleItem>
          ))
        ) : (
          <p>Inga nyheter tillgängliga just nu.</p>
        )}
      </ArticleList>

      <LoadMoreButton>Ladda fler nyheter</LoadMoreButton>

      <NewsletterContainer>
        <Title>Håll dig uppdaterad</Title>
        <Description>
          Få exklusiva erbjudanden, insikter och de senaste nyheterna inom kryptotrading direkt i din inkorg.
          Missa inte en sekund i kryptovärlden!
        </Description>

        <BenefitsSection>
          <BenefitItem>Exklusiva kampanjer och kryptoerbjudanden</BenefitItem>
          <BenefitItem>Uppdateringar om de senaste trenderna inom kryptotrading</BenefitItem>
          <BenefitItem>Inga spamtjänster – vi skickar bara relevant innehåll</BenefitItem>
          <BenefitItem>Avprenumerera när som helst, enligt GDPR</BenefitItem>
        </BenefitsSection>

        <EmailForm onSubmit={handleNewsletterSignup}>
          <Input
            type="email"
            placeholder="Ange din e-postadress"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <SubmitButton>Prenumerera</SubmitButton>
        </EmailForm>

        {notification && (
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={closeNotification}
          />
        )}

        <ConfirmationText>
          Genom att registrera dig godkänner du att vi kontaktar dig med erbjudanden och uppdateringar. Du kan när
          som helst avsluta prenumerationen.
        </ConfirmationText>
      </NewsletterContainer>
    </NewsContainer>
  );
};

export default News;

