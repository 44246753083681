import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, Link } from 'react-router-dom';
import {
  ArticleContainer,
  Title,
  DateInfo,
  Image,
  ImageCaption,
  ContentSection,
  Subtitle,
  Divider,
  RelatedArticlesContainer,
  MobileDivider,
} from './ArticleElements';

const Article = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);

  // Base URL for production
  const baseURL = 'https://kryptotrading.se/api/nyheter';

  // Fetch the current article
  useEffect(() => {
    fetch(`${baseURL}/articles/${slug}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch the article.');
        }
        return response.json();
      })
      .then((data) => setArticle(data))
      .catch((error) => console.error('Error fetching article:', error));
  }, [slug]);

  // Fetch related articles
  useEffect(() => {
    fetch(`${baseURL}/articles?exclude=${slug}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch related articles.');
        }
        return response.json();
      })
      .then((data) => setRelatedArticles(data.slice(0, 5))) // Limit to 5 related articles
      .catch((error) => console.error('Error fetching related articles:', error));
  }, [slug]);

  if (!article) return <p>Laddar...</p>;

  // Schema.org structured data for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: article.title,
    datePublished: article.published_date,
    dateModified: article.updated_date,
    author: {
      "@type": "Person",
      name: "Kryptotrading.se",
    },
    publisher: {
      "@type": "Organization",
      name: "Kryptotrading.se",
      logo: {
        "@type": "ImageObject",
        url: "https://www.kryptotrading.se/logo.png",
      },
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://www.kryptotrading.se/nyheter/${slug}`,
    },
    image: {
      "@type": "ImageObject",
      // Make sure these use the correct CDN URLs
      url: `https://kryptotrading-bilder.ams3.cdn.digitaloceanspaces.com/desktop/${article.image_url_desktop.split('/').pop()}`,
      height: 800,
      width: 1200,
    },
    description: article.content1 || `Läs nyheten om ${article.title}. Håll dig uppdaterad om kryptonyheter och trender.`,
  };

  return (
    <ArticleContainer>
      <Helmet>
  {/* Optimized Meta Title */}
  <title>{article.title} - Nyheter om Bitcoin & Krypto - Kryptotrading.se</title>

  {/* Optimized Meta Description */}
  <meta
    name="description"
    content={`Läs nyheten om ${article.title}. Håll dig uppdaterad om de senaste trenderna inom kryptotrading, Bitcoin och kryptovalutor.`}
  />

  {/* Keywords */}
  <meta
    name="keywords"
    content={`kryptonyheter, kryptovalutor, Bitcoin, kryptotrading, ${article.title}`}
  />

  {/* Open Graph Metadata */}
  <meta 
    property="og:title" 
    content={`${article.title} - Nyheter om Bitcoin & Krypto - Kryptotrading.se`} 
  />
  <meta
    property="og:description"
    content={`Läs nyheten om ${article.title}. Upptäck de senaste trenderna inom kryptotrading, Bitcoin och kryptovalutor.`}
  />
  <meta
    property="og:image"
    content={`https://kryptotrading-bilder.ams3.cdn.digitaloceanspaces.com/desktop/${article.image_url_desktop.split('/').pop()}`}
  />
  <meta property="og:type" content="article" />
  <meta
    property="og:url"
    content={`https://www.kryptotrading.se/nyheter/${slug}`}
  />

  {/* Article-Specific Metadata */}
  <meta property="article:published_time" content={article.published_date} />
  <meta property="article:modified_time" content={article.updated_date} />
  <meta property="article:author" content="Kryptotrading.se" />
  <meta property="article:section" content="Kryptonyheter" />
  <meta
    property="article:tag"
    content={`Bitcoin, kryptovalutor, kryptonyheter, kryptotrading, ${article.title}`}
  />

  {/* Canonical URL */}
  <link
    rel="canonical"
    href={`https://www.kryptotrading.se/nyheter/${slug}`}
  />

  {/* Structured Data (Schema.org) */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      headline: article.title,
      datePublished: article.published_date,
      dateModified: article.updated_date,
      author: {
        "@type": "Person",
        name: "Kryptotrading.se",
      },
      publisher: {
        "@type": "Organization",
        name: "Kryptotrading.se",
        logo: {
          "@type": "ImageObject",
          url: "https://www.kryptotrading.se/logo.png",
        },
      },
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `https://www.kryptotrading.se/nyheter/${slug}`,
      },
      image: {
        "@type": "ImageObject",
        url: `https://kryptotrading-bilder.ams3.cdn.digitaloceanspaces.com/desktop/${article.image_url_desktop.split('/').pop()}`,
        height: 800,
        width: 1200,
      },
      description: article.content1 || `Läs nyheten om ${article.title}. Håll dig uppdaterad om kryptonyheter och trender.`,
    })}
  </script>
</Helmet>

      {/* Main content */}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {/* Left Section */}
        <div style={{ flex: 3 }}>
          <Title>{article.title}</Title>
          <DateInfo>
            Publicerad: {new Date(article.published_date).toLocaleDateString()} |
            Uppdaterad: {new Date(article.updated_date).toLocaleDateString()}
          </DateInfo>
          <Image
            src={`https://kryptotrading-bilder.ams3.cdn.digitaloceanspaces.com/desktop/${article.image_url_desktop.split('/').pop()}`}
            alt={article.image_caption || article.title}
          />
          <ImageCaption>{article.image_caption}</ImageCaption>

          {/* Article Content Sections */}
          {article.subtitle1 && article.content1 && (
            <ContentSection>
              <Subtitle>{article.subtitle1}</Subtitle>
              <p>{article.content1}</p>
            </ContentSection>
          )}
          {article.subtitle2 && article.content2 && (
            <ContentSection>
              <Subtitle>{article.subtitle2}</Subtitle>
              <p>{article.content2}</p>
            </ContentSection>
          )}
          {article.subtitle3 && article.content3 && (
            <ContentSection>
              <Subtitle>{article.subtitle3}</Subtitle>
              <p>{article.content3}</p>
            </ContentSection>
          )}

          {/* Mobile Divider */}
          <MobileDivider />
        </div>

        {/* Right Section - Related Articles */}
        <RelatedArticlesContainer>
          <h2>Fler artiklar</h2>
          <ul>
            {relatedArticles.map((relatedArticle) => (
              <li key={relatedArticle.id}>
                <Link to={`/nyheter/${relatedArticle.slug}`} rel="noopener noreferrer">
                  {relatedArticle.title}
                </Link>
              </li>
            ))}
          </ul>
        </RelatedArticlesContainer>
      </div>
    </ArticleContainer>
  );
};

export default Article;

