import React from 'react';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLink,
  WebsiteRights,
  LogoContainer,
  PartnerLogo,
  FooterText
} from './FooterElements';

import BitcoinLogo from '../../images/icons/bitcoin-btc-logo.svg';
import EthereumLogo from '../../images/icons/ethereum-eth-logo-diamond-purple.svg';
import SolanaLogo from '../../images/icons/solana-sol-logo.svg';
import TetherLogo from '../../images/icons/tether-usdt-logo.svg';
import BnbLogo from '../../images/icons/bnb-bnb-logo.svg';

const Footer = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Kryptotrading.se",
    url: "https://www.kryptotrading.se",
    logo: "https://www.kryptotrading.se/logo.png",
    sameAs: [
      "https://www.facebook.com/kryptotrading",
      "https://www.twitter.com/kryptotrading",
      "https://www.linkedin.com/company/kryptotrading"
    ],
    description: "Kryptotrading.se hjälper svenska traders att navigera kryptovalutamarknaden med guider, nyheter och plattformsrecensioner.",
    foundingDate: "2024",
    contactPoint: {
      "@type": "ContactPoint",
      email: "support@kryptotrading.se",
      contactType: "Customer Service",
      areaServed: "SE",
      availableLanguage: ["Svenska"]
    }
  };

  return (
    <FooterContainer role="contentinfo" aria-label="Sidfot för kryptotrading.se">
      <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      <FooterWrap>
        <FooterLinksContainer aria-label="Viktiga länkar">
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLink to='/integritetspolicy' aria-label="Läs vår integritetspolicy">Integritetspolicy</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLink to='/anvandarvillkor' aria-label="Läs våra användarvillkor">Användarvillkor</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLink to='/kontakt' aria-label="Kontakta oss">Kontakta oss</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLink to='/om-oss' aria-label="Läs om oss">Om oss</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>

        <FooterText>
          <p>
            Kryptohandel med kryptovalutor som Bitcoin, Ethereum och Solana innebär hög risk och kan resultera i kapitalförlust. Handla med omsorg och överväg riskerna noggrant.
          </p>
        </FooterText>

        <LogoContainer aria-label="Partners logotyper">
  {/* Bitcoin Official Page */}
  <a
    href="https://bitcoin.org"
    target="_blank"
    rel="noopener noreferrer nofollow"
    aria-label="Officiell sida för Bitcoin"
  >
    <PartnerLogo
      src={BitcoinLogo}
      alt="Bitcoin Logo - ledande kryptovaluta för kryptohandel"
      loading="lazy"
    />
  </a>
  {/* Ethereum Official Page */}
  <a
    href="https://ethereum.org"
    target="_blank"
    rel="noopener noreferrer nofollow"
    aria-label="Officiell sida för Ethereum"
  >
    <PartnerLogo
      src={EthereumLogo}
      alt="Ethereum Logo - populär för smarta kontrakt och decentraliserade applikationer"
      loading="lazy"
    />
  </a>
  {/* Solana Official Page */}
  <a
    href="https://solana.com"
    target="_blank"
    rel="noopener noreferrer nofollow"
    aria-label="Officiell sida för Solana"
  >
    <PartnerLogo
      src={SolanaLogo}
      alt="Solana Logo - snabb blockchain-plattform för skalbara applikationer"
      loading="lazy"
    />
  </a>
  {/* Tether Official Page */}
  <a
    href="https://tether.to"
    target="_blank"
    rel="noopener noreferrer nofollow"
    aria-label="Officiell sida för Tether"
  >
    <PartnerLogo
      src={TetherLogo}
      alt="Tether Logo - stabil kryptovaluta för säkra transaktioner"
      loading="lazy"
    />
  </a>
  {/* Binance Coin Official Page */}
  <a
    href="https://www.bnbchain.org"
    target="_blank"
    rel="noopener noreferrer nofollow"
    aria-label="Officiell sida för Binance Coin"
  >
    <PartnerLogo
      src={BnbLogo}
      alt="BNB Logo - Binance kryptovaluta för decentraliserad handel"
      loading="lazy"
    />
  </a>
</LogoContainer>

        <WebsiteRights aria-label="Kryptotrading upphovsrätt">
          Kryptotrading.se © {new Date().getFullYear()} Alla rättigheter förbehållna
        </WebsiteRights>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;

